import React, { FunctionComponent, useRef, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

import "./styles.scss";

const ImageGallery: FunctionComponent = (props) => {
    const { sanityId, sanityDataset } = useSiteMetadata();
    const urlBuilder = sanityImageUrlBuilder({
        projectId: sanityId,
        dataset: sanityDataset
    });
    const { bgColor, images } = props;

    return (
        <>
            <section className={`rx-image-gallery rx-theme-${bgColor?.title?.toLowerCase() || "teal"}`}>
                <Container fluid>
                    <Row className={"grid-row"}>
                        {images._rawImages.map((image, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Col xs={10} md={4} style={{ marginTop: "15px", marginBottom: "15px" }}>
                                  <picture
                                    style={
                                      image.asset.metadata
                                        ? {
                                            background: `url(${image.asset.metadata.lqip})`,
                                            backgroundSize: "cover"
                                          }
                                        : undefined
                                    }
                                  >
                                    <source
                                      media={"(min-width: 1200px)"}
                                      srcSet={
                                        urlBuilder
                                          .image(image.asset)
                                          .auto("format")
                                          .quality(80)
                                          .width(500)
                                          .height(500)
                                          .format("webp")
                                          .url() || undefined
                                      }
                                    />
                                    <source
                                      media={"(min-width: 768px)"}
                                      srcSet={
                                        urlBuilder
                                          .image(image.asset)
                                          .auto("format")
                                          .quality(80)
                                          .width(500)
                                          .height(500)
                                          .format("webp")
                                          .url() || undefined
                                      }
                                    />
                                    <img
                                      width="501"
                                      height="466"
                                      className="img-fluid secondary-image"
                                      src={
                                        urlBuilder
                                          .image(image.asset)
                                          .auto("format")
                                          .quality(80)
                                          .width(500)
                                          .height(500)
                                          .format("webp")
                                          .url() || undefined
                                      }
                                      loading={"lazy"}
                                      alt={image.alt as string}
                                    />
                                  </picture>
                                </Col>
                              </React.Fragment>
                            );
                        })}
                    </Row>
                </Container>
            </section>
        </>
    );
};
export default ImageGallery;
